import React, { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import Layout from 'components/Layout';
import { Section, SectionTitle, Inner } from 'components';
import { useFilterContext } from 'contexts/filter';
import AreasOfInterestFilter from 'components/AreasOfInterestFilter';
// import SelectCountry from 'components/SelectCountry';
import Filter from 'components/Filter';
import TiltButton from 'components/TiltButton';

const Row = styled.div(({ valid }) => [
  tw`text-center mt-16 opacity-50 transition-opacity pointer-events-none`,
  valid && tw`opacity-100 pointer-events-auto`,
]);

const FunnelPage = () => {
  const { roleFilters, areasOfInterest } = useFilterContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'techTalentFunnelStarted',
      });
    }
  }, []);

  const resultsPath = `/opportunities/results`;
  const params = new URLSearchParams();

  roleFilters.forEach(filter => filter && params.append('roles', filter.label));
  areasOfInterest.forEach(filter => filter && params.append('interests', filter.label));

  const resultsUrl = `${resultsPath}${params ? `?${params.toString()}` : ''}`;

  return (
    <Layout noFooter>
      <Section>
        <Inner>
          <Row valid>
            <SectionTitle tw="mb-8">What is your profile?</SectionTitle>
            <Filter inFunnel withIcons />
          </Row>
          <Row valid={roleFilters.length > 0}>
            <SectionTitle tw="mb-8">What are you interested in?</SectionTitle>
            <AreasOfInterestFilter />
          </Row>
          {/* <Row valid={areasOfInterest.length > 0}>
            <SectionTitle tw="mb-8">Where are you from?</SectionTitle>
            <SelectCountry />
          </Row> */}
          <Row tw="mt-0" valid={roleFilters.length > 0 && areasOfInterest.length > 0}>
            <TiltButton
              label="See my job opportunities"
              to={resultsUrl}
              tw="mt-16"
              accent="white"
            />
          </Row>
        </Inner>
      </Section>
    </Layout>
  );
};

export default FunnelPage;
